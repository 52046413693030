import { gql } from "@apollo/client";
import { DocumentNode } from "graphql";

import { USER_FRAGMENT } from "~apollo/graphql-gateway/fragments/userFragments";

const getUserInfo: DocumentNode = gql`
  query getUser($id: ID) {
    User(filter: { id: $id }) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

const updateUserInfo: DocumentNode = gql`
  mutation updateUserInfo(
    $accountEmail: String
    $firstName: String
    $lastName: String
  ) {
    User {
      update(
        accountEmail: $accountEmail
        firstName: $firstName
        lastName: $lastName
      ) {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

const deleteUser: DocumentNode = gql`
  mutation deleteUser($userID: ID) {
    User {
      remove(id: $userID) {
        message
      }
    }
  }
`;

const confirmEmailNotExist: DocumentNode = gql`
  mutation confirmEmailDoesNotExist($accountEmail: String) {
    User {
      confirmEmailDoesNotExist(accountEmail: $accountEmail) {
        message
      }
    }
  }
`;

const guestVerify: DocumentNode = gql`
  mutation guestVerify(
    $accountEmail: String
    $firstName: String
    $lastName: String
  ) {
    User {
      guestVerify(
        accountEmail: $accountEmail
        firstName: $firstName
        lastName: $lastName
      ) {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;
const createGuestUser: DocumentNode = gql`
  mutation createGuest {
    User {
      createGuest {
        user {
          id
          createdDate
        }
        magicToken
      }
    }
  }
`;

const getUserShareStatus: DocumentNode = gql`
  query($id: ID) {
    User(filter: { id: $id }) {
      indeedShareStatus
    }
  }
`;

const connectOtherUserAccounts: DocumentNode = gql`
  mutation connectOtherUserAccounts($accountEmail: String, $userID: ID) {
    User(id: $userID) {
      connectOtherUserAccounts(accountEmail: $accountEmail) {
        message
      }
    }
  }
`;

const signInWithIndeed: DocumentNode = gql`
  mutation signInWithIndeed($code: String) {
    User {
      signInWithIndeed(code: $code) {
        magicToken
        indeedEmail
        accessToken
        refreshToken
      }
    }
  }
`;

const connectUserAccount: DocumentNode = gql`
  mutation connectUserAccount($userID: ID, $connectToken: String) {
    User(id: $userID) {
      connectUserAccount(connectToken: $connectToken) {
        ...UserFragment
      }
    }
  }
  ${USER_FRAGMENT}
`;

const connectWithIndeed: DocumentNode = gql`
  mutation connectWithIndeed($userID: ID, $code: String) {
    User(id: $userID) {
      connectWithIndeed(code: $code) {
        message
      }
    }
  }
`;

const contactUs: DocumentNode = gql`
  mutation contactUs(
    $userEmail: String!
    $fullName: String!
    $subject: String!
    $message: String!
  ) {
    User {
      contactUs(
        userEmail: $userEmail
        fullName: $fullName
        subject: $subject
        message: $message
      ) {
        message
      }
    }
  }
`;

const ShareResumeToIndeed: DocumentNode = gql`
  mutation ShareResumeToIndeed(
    $id: ID
    $appId: String
    $isPrivate: Boolean
    $sharedResumeHash: String
  ) {
    User {
      shareWithIndeed(
        id: $id
        appId: $appId
        isPrivate: $isPrivate
        sharedResumeHash: $sharedResumeHash
      ) {
        message
      }
    }
  }
`;

const getIndeedProfile: DocumentNode = gql`
  mutation getIndeedProfile {
    User {
      getIndeedProfile {
        profile {
          resume {
            id
            source
            visibility
          }
        }
      }
    }
  }
`;

export const userQueries = {
  getUserShareStatus
};

export const userMutations = {
  updateUserInfo,
  deleteUser,
  confirmEmailNotExist,
  guestVerify,
  createGuestUser,
  getUserInfo,
  connectOtherUserAccounts,
  signInWithIndeed,
  connectUserAccount,
  connectWithIndeed,
  contactUs,
  ShareResumeToIndeed,
  getIndeedProfile
};
