import { ResumeValidation } from "resumecom-validations";

// from https://emailregex.com/
// eslint-disable-next-line no-useless-escape
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// options
const options = {};

// transform to frontend validator decorator
const transformValidator = validator => (input: any) => {
  return validator.validate(input, options);
};

const {
  education: edValid,
  employment: emValid,
  skills: skValid,
  languages: lgValid,
  contactDetails: cdValid,
  awards: awValid,
  certificates: ceValid
} = ResumeValidation;

export const contactDetails = transformValidator(cdValid);
export const education = transformValidator(edValid);
export const employment = transformValidator(emValid);
export const skill = transformValidator(skValid);
export const language = transformValidator(lgValid);
export const award = transformValidator(awValid);
export const certificate = transformValidator(ceValid);

export const emailValidator = (input: string): boolean => {
  const isValid = emailRegex.test(input);
  return isValid;
};

export const hyperlinkValidator = (
  url: string
): { link: string; isValid: boolean } => {
  try {
    const urlRegex = /^https?:\/\//;
    let prefix = "";
    if (!urlRegex.test(url)) prefix = "https://";
    const link = prefix + url.trim();
    return { link: link, isValid: true };
  } catch (e) {
    return { link: "", isValid: false };
  }
};

export const isLinkValid = (
  str: string
): { link: string; isValid: boolean } => {
  const urlPattern = /^(http|https):\/\/[\w.-]+\.\w{2,}$/;
  return { link: str, isValid: urlPattern.test(str) };
};
