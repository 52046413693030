import React from "react";

import { useModalValue } from "~context/ModalContext";

const ModalRoot = () => {
  const modalContext = useModalValue();
  const { component, props, hideModal } = modalContext;
  if (!component) {
    return null;
  }

  return React.createElement(component, {
    ...props,
    onRequestClose: hideModal
  });
};

export default ModalRoot;
