/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
import React, { ReactNode } from "react";

import { ApolloProvider } from "@apollo/client";
import { GatsbyBrowser } from "gatsby";
import { createRoot } from "react-dom/client";
import Modal from "react-modal";

import { client } from "~apollo/graphql-gateway/client";
import InitPreparation from "~components/initPreparation";
import Maintenance from "~components/maintenance";
import RootModal from "~components/modalWeb/modalRoot";
import { AuthProvider } from "~context/AuthContext";
import { ModalProvider } from "~context/ModalContext";
import { ThemeProvider } from "~context/ThemeContext";
import { isMobileOnly } from "~lib/deviceDetect";
import { MODAL_OPEN } from "~lib/keyNamesOfLocalStorage";

const reactModalComponentDidMount = Modal.prototype.componentDidMount;
const reactModalComponentWillUnmount = Modal.prototype.componentWillUnmount;
let modalOpenNumber = 0;

const avoidFocusPenetration = (): void => {
  const modalNodeList = document.getElementsByClassName("ReactModalPortal");
  const modalList = Array.from(modalNodeList);
  modalList.forEach((el, index) => {
    if (index !== modalList.length - 1) {
      modalNodeList[index].setAttribute("aria-hidden", "true");
    }
  });
};

const removeAvoidFocusPenetration = (): void => {
  const modalNodeList = document.getElementsByClassName("ReactModalPortal");
  const modalList = Array.from(modalNodeList);
  modalList.forEach((el, index) => {
    if (index === modalList.length - 1) {
      modalNodeList[index].removeAttribute("aria-hidden");
    }
  });
};

Modal.prototype.componentDidMount = function() {
  const scrollHeight = document.documentElement.scrollTop;
  localStorage.setItem(MODAL_OPEN, "true");
  setTimeout(() => {
    modalOpenNumber++;
    let scrollTop = document.body.style.top
      ?.replace("-", "")
      ?.replace("px", "");
    if (scrollTop === "unset") {
      scrollTop = "0";
    }
    const scrollY = Math.max(scrollHeight, Number(scrollTop));
    avoidFocusPenetration();
    modalOpenNumber >= 1 &&
      this.props.isOpen &&
      (document.body.style.position = "fixed") &&
      (document.body.style.width = "100%") &&
      (document.body.style.overflow = "hidden") &&
      !isMobileOnly &&
      (document.body.style.top = -scrollY + "px");
    this.scrollTop = scrollY;
  });
  reactModalComponentDidMount.apply(this);
};

Modal.prototype.componentWillUnmount = function() {
  localStorage.removeItem(MODAL_OPEN);
  setTimeout(() => {
    modalOpenNumber--;
    removeAvoidFocusPenetration();
    modalOpenNumber === 0 &&
      document.body &&
      (document.body.style.position = "unset") &&
      (document.body.style.width = "unset") &&
      (document.body.style.overflow = "auto") &&
      (document.body.style.top = "unset");
    window.scrollTo(0, this.scrollTop);
  });
  reactModalComponentWillUnmount.apply(this);
};

export const wrapRootElement: GatsbyBrowser["wrapPageElement"] = ({
  element
}) => (
  <ApolloProvider client={client}>
    <ThemeProvider>
      <AuthProvider>
        <ModalProvider>
          <RootModal />
          {element}
        </ModalProvider>
      </AuthProvider>
    </ThemeProvider>
  </ApolloProvider>
);

export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({
  prevRouterProps,
  routerProps,
  getSavedScrollPosition
}) => {
  if (
    prevRouterProps &&
    prevRouterProps.location &&
    prevRouterProps.location.pathname !== routerProps.location.pathname
  ) {
    const currentPosition = getSavedScrollPosition(routerProps.location);
    window.scrollTo(...(currentPosition || [0, 0]));
  } else {
    window.scrollTo(0, 0);
  }
  return false;
};

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element
}) => (
  <Maintenance>
    <InitPreparation>{element}</InitPreparation>
  </Maintenance>
);

// https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/#replaceHydrateFunction
export const replaceHydrateFunction = () => {
  return (element: ReactNode, container: HTMLElement): void => {
    const root = createRoot(container);
    root.render(element);
  };
};
