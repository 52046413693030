// ********************* AB test *********************
export const AB_TESTS = "ab_tests";
export const FORCE_AB_TESTS = "force_ab_tests";
export const AMPLIFY_TEST_NAME = "__amplify__test_name";
export const AMPLIFY_RBK = "__amplify____rbk";

// ********************* localStorage.js *********************
// Set user data
export const RESUME_USER = "resume_user";
export const IS_USER = "isUser";
export const TEMPLATE = "template";
export const RANDOM_FIRST_NAMES = "random_first_names";
export const RANDOM_LAST_NAMES = "random_last_names";

//Sign-in with Indeed token
export const ACCESS_TOKEN = "accessToken";
export const REFRESH_TOKEN = "refreshToken";

// ********************* Set in log-page-view.js *********************
export const CURRENT_SESSION = "currentSession";
export const SESSION_ID = "sessionId";
export const SESSION_START_TIME = "sessionStartTime";
export const SESSION_LAST_INTERACTION = "sessionLastInteraction";
export const SESSION_COUNT = "sessionCount";
export const SESSION_LANDING_PAGE = "sessionLandingPage";

export const AMPLIFY_CURRENT_RESUME_ID = "__amplify__currentResumeID";

export const LOG_PAGE_VIEWS = "logPageviews";
export const LOG_PAGE_VIEWS_RUM = "logPageViewsRum";

// Set in log
export const PREVIOUS_PATH = "previousPath";

// ********************* Other components and functions *********************
// Not sure if still needed
export const AMPLIFY_METEOR_USERS = "__amplify____meteorUsers";

export const FIRST_VISIT_STATE = "firstVisitState";
export const FORCE_LOGOUT = "forceLogout";

// Sign in with Indeed - state
export const RANDOM_STR_TO_PREVENT_CSRF = "randomStrToPreventCSRF";

// Prevent the scroll when modalOpen
export const MODAL_OPEN = "modalOpen";

// Deleting account status, Prevent users from manually entering a resume/cover letter that doesn't exist
export const DELETING_USER = "deletingUser";

// Process Download status
export const DOWNLOADING = "Downloading";
export const HIDE_FREE_TAG = "hideFreeTag";

// Spellcheck result
export const SPELLCHECK_RESULT = "result";
export const SPELLCHECK_IGNORE_WORDS = "spellcheckIgnoreWords";

// LOWRES - Explore more red point
export const EXPLORE_MORE_RED_POINT = "ExpMRedPoint";
// LOWRES - Share resume to Indeed red point
export const R2I_RED_POINT = "R2IRedPoint";
// LOWRES - AI Writer red point
export const AI_WRITER_RED_POINT = "AIWriter";

// LOWRES - Preview - The Tap to View modal disappear automatically
export const HAVE_SAW_USER_GUIDE = "have_saw_user_guide";

// LOWRES - Manage section - hide section
export const HIDE_SECTION_FIRSTLY = "hideSectionFirstly";

// LOWRES - AI writer
export const DISAPPEAR_AI_TAG = "disappearAITag";
export const DISAPPEAR_AI_EXPERIENCE_TIP = "disappearAIExperienceTip";

// For creating resume from Use Sample modal in resumeSideBar
export const NEW_RESUME_DATA_BY_SAMPLE_MODAL = "newResumeDataBySample";
// For creating resume from resume sample page
export const RESUME_SAMPLE = "resumeSample";
// For creating cover letter from cover letter sample page
export const COVER_LETTER_SAMPLE_TEMPLATE = "coverLetterSampleTemplate";
// Prevent the guest user creates a second one from the cover letter sample page when the guest user has one cover letter
export const COVER_LETTER_SAMPLE = "coverletterSample";

// Click SIWI modal from navbar, default redirect the user dashboard page
export const CLICK_FROM_NAV_BAR = "clickFromNavBar";

// Download logging - Only log once DownloadNudge
export const NOT_LOG_DOWNLOAD_NUDGE = "notLogDownloadNudge";

// Record the guest user's last operations before logging in. After successful login, the user needs to jump to the corresponding function.
export const LAST_OPERATION = "lastOperation";
// Set the page to be redirected after the user login successfully
export const RESTORE_PATH = "restorePath";
// Set the logged in Indeed Email
export const INDEED_EMAIL = "indeedEmail";

// AI writer - For saving the unsavedExperienceData when click the AI writer
export const UNSAVED_AI_WRITER_RESUME = "unsavedAIWriterResume";
// AI Writer - Only auto show once the Tips in AI Writer Generated Modal
export const HAS_ALREADY_AUTO_SHOWN_TIPS = "hasAlreadyAutoShownTips";
// AI Writer - If user click aiWriter button avoid the highlightStrip animation
export const HAVE_CLICKED_AI_WRITER = "haveClickedAIWriter";
// AI Writer - If user sign in through clicking AI writer button, the RebrandingWithIndeedMessage should not appear
export const SIGN_IN_CLICKED_AI_WRITER = "signInClickedAIWriter";
//AI Writer - Show once if user close the animationModal
export const HIDE_AI_WRITER_ANIMATION_MODAL = "hideAIWriterAnimationModal";
//AI Writer - Show once quick survey modal for user who click quit button
export const HIDE_AI_WRITER_QUICK_SURVEY_MODAL = "hideAIWriterQuickSurveyModal";
//LOWRES AI Writer - Show once quick survey modal for user who click quit button
export const HIDE_MOBILE_AI_WRITER_QUICK_SURVEY_MODAL =
  "hideMobileAIWriterQuickSurveyModal";
//Survey modal - hide until IQL development
export const SHOW_AI_WRITER_QUICK_SURVEY_MODAL_ON_DEV =
  "showAIWriterQuickSurveyModalOnDev";
//AI writer - Show once tour guide for user clicks the AI writer button
export const HIDE_AI_WRITER_TOUR_GUIDE_MODAL = "hideAIWriterTourGuideModal";

// Feedback modal - Only show once the Feedback modal
export const HAS_ALREADY_SHOWN_FEEDBACK_MODAL = "hasAlreadyShownFeedbackModal";

// Guest user - Save the length of guest user's resume
export const GUEST_USER_RESUMES = "guest_user_resumes";
// Guest user - Save the length of guest user's cover letter
export const GUEST_USER_LETTERS = "guest_user_letters";

// Preview images
export const RESUME_IMAGES = "resume_images";
export const COVER_LETTER_IMAGES = "cover_letter_images";

// Modal open keys
export const SAMPLE_MODAL_OPEN_KEY = "sampleModal";
export const UPLOAD_RESUME_MODAL_OPEN_KEY = "uploadResumeModal";
export const FEEDBACK_MODAL_OPEN_KEY = "feedbackModal";

// Resume limitation modal
export const SHOW_RESUME_LIMITATION_MODAL = "showResumeLimitationModal";
export const GUEST_USER_RESUME = "guestUserResume";

// Create resume guide
export const HIDE_CREATE_RESUME_GUIDE = "hideCreateResumeGuide";

// Resume interview copilot card record
export const RESUME_INTERVIEW_COPILOT_CARD_RECORD =
  "resumeInterviewCopilotCardRecord";

// Cover letter interview copilot card record
export const COVER_LETTER_INTERVIEW_COPILOT_CARD_RECORD =
  "coverLetterInterviewCopilotCardRecord";

// Resume color record
export const RESUME_COLOR_RECORD = "resumeColorRecord";

export const NEED_CLEAR_WHEN_LOGOUT = [
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  CLICK_FROM_NAV_BAR,
  COVER_LETTER_IMAGES,
  GUEST_USER_RESUMES,
  GUEST_USER_LETTERS,
  INDEED_EMAIL,
  RESUME_IMAGES,
  RESTORE_PATH,
  SPELLCHECK_IGNORE_WORDS,
  UNSAVED_AI_WRITER_RESUME,
  COVER_LETTER_SAMPLE,
  COVER_LETTER_SAMPLE_TEMPLATE,
  RESUME_SAMPLE,
  NEW_RESUME_DATA_BY_SAMPLE_MODAL,
  SAMPLE_MODAL_OPEN_KEY,
  UPLOAD_RESUME_MODAL_OPEN_KEY,
  SHOW_RESUME_LIMITATION_MODAL,
  GUEST_USER_RESUME
];
