import React, {
  FC,
  FunctionComponent,
  ReactNode,
  createContext,
  useContext,
  useState
} from "react";

interface ModalContextProps {
  component: any;
  props: any;
  showModal: (component: FunctionComponent, props: any) => void;
  hideModal: () => void;
}

type ModalProviderType = {
  children?: ReactNode;
};

export const ModalContext = createContext<ModalContextProps>({
  component: null,
  props: {},
  showModal: () => {},
  hideModal: () => {}
});

export const ModalProvider: FC<ModalProviderType> = ({ children }) => {
  const [component, setComponent] = useState(null);
  const [props, setProps] = useState({});

  const showModal = (component: FunctionComponent, props = {}) => {
    setComponent(component);
    setProps(props);
  };

  const hideModal = () => {
    setComponent(null);
    setProps({});
  };

  return (
    <ModalContext.Provider
      value={{ component, props, showModal: () => showModal, hideModal }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModalValue = (): ModalContextProps => useContext(ModalContext);

export const ModalConsumer = ModalContext.Consumer;
